export const ServiceUrl = {
  V2_LOGIN: {
    CHECK_USERNAME: "v2/login/check-username",
    CAPTCHA: "v1/captcha",
    VERIFY_CAPTCHA: "v2/login/verify-captcha",
    UPDATE_PASSWORD: "v2/login/update-pass",
  },
  DASHBOARD: {
    FIRST_LINE_COUNT: "v1/admin/dashboard/first-line-counts",
    STUDENTS_BIRTHDAY_LIST: "v1/admin/dashboard/students-birthday-list",
    STAFFS_BIRTHDAY_LIST: "v1/admin/dashboard/staffs-birthday-list",

    STUDENTS_COUNT: "v1/admin/dashboard/students-count",
    TODAY_DAY_ORDER: "v1/admin/dashboard/today-day-order",
    TODAY_ATTENDANCE_COUNT: "v1/admin/dashboard/today-attendance-count",
  },
  ACADEMIC: {
    LIST_ACADEMIC_YEARS: "v1/admin/academic_years/list-academic-years",
    LIST_COURSES: "v1/admin/courses/list-courses",
    LIST_COURSES_V2: "v1/admin/courses/list-courses", // New Course List based on v2 Department
    REMOVE_COURSE_FROM_DEPT: "v1/admin/courses/remove-course-from-dept", // New Course List based on v2 Department

    SAVE_COURSES: "v1/admin/courses/save-course",
    UPDATE_COURSES: "v1/admin/courses/update-course",
    REMOVE_COURSES: "v1/admin/courses/remove-course",
  },
  LOGIN_V2: {
    CHECK: "/v2/admin/check",
    CHECK_USERNAME: "/v1/admin/login",
  },
  ADMISSION: {
    SETTINGS: "v1/settings",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/list-settings",
    UPDATE_VARIABLE_SETTINGS: "v1/admin/settings/update-settings",
    ADD_VARIABLE_SETTINGS: "v1/admin/settings/add-settings",

    LIST_SMS_TEMPLATES: "v1/admin/smstemplate/list-templates",
    UPDATE_SMS_TEMPLATES: "v1/admin/smstemplate/update-template",
    ADD_SMS_TEMPLATES: "v1/admin/smstemplate/add-template",
    DELETE_SMS_TEMPLATES: "v1/admin/smstemplate/delete-template",

    LIST_MAIL_TEMPLATES: "v1/admin/mailtemplate/list-templates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/mailtemplate/update-template",
    ADD_MAIL_TEMPLATES: "v1/admin/mailtemplate/add-template",
    DELETE_MAIL_TEMPLATES: "v1/admin/mailtemplate/delete-template",

    LIST_PAGES: "v1/admin/webpage/list-pages",
    UPDATE_PAGE: "v1/admin/webpage/update-page",
    VIEW_PAGE: "v1/admin/webpage/view-page",
    DELETE_PAGE: "v1/admin/webpage/delete-page",
    ADD_PAGE: "v1/admin/webpage/add-page",

    USER_PROFILE: "v1/admin/users/my-profile",
    USER_CHANGE_PASSWORD: "v1/admin/users/change-password",

    NEW_USER_LOGIN: "v1/admin/users/save-user",
    LIST_USERS_LOGIN: "v1/admin/users/list-users",
    USER_STATUS_CHANGE: "v1/admin/users/status-change",
    REMOVE_USER_LOGIN: "v1/admin/users/remove-user",
    UPDATE_USER_LOGIN: "v1/admin/users/update-user",
    PASS_USER_LOGIN: "v1/admin/users/user-password",
  },
  STUDENTS: {
    SEARCH_BY_REGISTER_NO: "v1/admin/student_search/search-student-by-no",
    SEARCH_BY_OVERALL_REPORT: "v1/admin/student_search/search-student-overall",
    ID_CARD_DOWNLOAD: "v1/admin/student_search/id-card-download",
    ZIP: "v1/admin/student_search/zip",
    SEARCH_BY_NAME: "v1/admin/student_search/search-by-name",
    INCOMPLETE_PROFILES: "v1/admin/student_search/incomplete",

    SAVE_BUS_ROUTE: "v1/admin/student/save-vehicle",
    TRANSPORT_DETAILS: "v1/admin/student_search/transport-details",
    PROMOTION_DETAILS: "v1/admin/student_search/promotion-details",
    SAVE_PROMOTION: "v1/admin/student/save-promotion",
    REMOVE_PROMOTION: "v1/admin/student/remove-promotion",
    UPDATE_BULK_FIELD: "v1/admin/student/update-bulk-field",

    SAVE_STUDENT: "v1/admin/student/save-student",
    UPDATE_STUDENT: "v1/admin/student/update-student",
    UPDATE_HOSTEL_DATES: "v1/admin/student/update-hostel-dates",
    DELETE_STUDENT: "v1/admin/student/delete",

    STUDENT_STRENGTH: "v1/admin/student_report/current-strength",
    STUDENTS_PROFILE_INCOMPLETE_COUNT:
      "v1/admin/student_report/incomplete-count",
    STUDENTS_PROFILE_INCOMPLETE_LIST:
      "v1/admin/student_report/incomplete-students",
    INCOMPLETE_REPORT: "v1/admin/student_report/incomplete-report",

    IMPORT_STUDENT: "v1/admin/importstudent/save",
    BULK_PHOTO_UPLOAD: "v1/admin/student/bulk-photo-upload",
    BULK_UPDATE: "v1/admin/student/bulk-update",
    UPDATE_TC_DATES: "v1/admin/student/tc-date-update",
    SAVE_STUDENT_TC: "v1/admin/student/save-tc",
    LIST_STUDENT_TC: "v1/admin/student/list-tc",
    LIST_PENDING_DETAILS: "v1/admin/student/list-tc-pending-details",
    COUNT_PENDING_DETAILS: "v1/admin/student/count-tc-pending-details",
    UPDATE_TRANSPORT_ID: "v1/admin/student/update-transport-id",

    SAVE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/save",
    LIST_BONAFIED_CERTIFICATES: "v1/admin/student/bonaifed/list-all",
    DELETE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/delete",
    SAVE_VERIFICATION_CERTIFICATE: "v1/admin/student/verification/save",
    LIST_VERIFICATION_CERTIFICATES: "v1/admin/student/verification/list-all",
    /* DELETE_VERIFICATION_CERTIFICATE: "v1/admin/student/bonaifed/delete", */
    SAVE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/save",
    LIST_CONDUCT_CERTIFICATES: "v1/admin/student/conduct/list-all",
    DELETE_CONDUCT_CERTIFICATE: "v1/admin/student/conduct/delete",
    SAVE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/save",
    LIST_MEDIUM_CERTIFICATES: "v1/admin/student/medium/list-all",
    DELETE_MEDIUM_CERTIFICATE: "v1/admin/student/medium/delete",
    LIST_VERIFY_CERTIFICATE: "v1/admin/student/verification/list",
    SAVE_VERIFY_CERTIFICATE: "v1/admin/student/verification/save",
    SAVE_ATTENDANCE_CERTIFICATE: "v1/admin/student/attendance/save",
    LIST_ATTENDANCE_CERTIFICATES: "v1/admin/student/attendance/list-all",
    MY_ATTENDANCE_DATA: "v1/admin/student/attendance/my-attendance-data",

    SAVE_COMPLETION_CERTIFICATE: "v1/admin/student/completion/save",
    LIST_COMPLETION_CERTIFICATES: "v1/admin/student/completion/list-all",
    DELETE_COMPLETION_CERTIFICATE: "v1/admin/student/completion/delete",

    SAVE_ID_CARD: "v1/admin/student/idcard/save",
    LIST_ID_CARD: "v1/admin/student/idcard/list-all",
    DELETE_ID_CARD: "v1/admin/student/idcard/delete",

    DATE_WISE_ATTENDANCE: "v1/admin/student/attendance/get",
    UPDATE_DAY_ATTENDANCE: "v1/admin/student/attendance/update",
    SAVE_LONG_ABSENT_STUDENT: "v1/admin/student/attendance/save-long-absent",
    LONG_ABSENT_STUDENTS: "v1/admin/student/attendance/long-absentee",
    GET_MARKED_GROUP_BY_DAYHOUR: "v1/admin/student/attendance/get-marked",
    DELETE_LONG_ABSENT_STUDENT:
      "v1/admin/student/attendance/delete-long-absentee",
    DAy_WISE_ENTRY_RECORDS: "v1/admin/student/attendance/get-day-records",
    DELETE_DAY_ENTRY: "v1/admin/student/attendance/delete-day-records",
    MARK_BULK_OD: "v1/admin/student/attendance/mark-bulkod",
    DAY_WISE_ATTENDANCE_COUNT: "v1/admin/student/attendance/day-wise-count",
    SAVE_SINGLE_OD_ENTRY: "v1/admin/student/attendance/single-od-update",

    ATTENDANCE_COUNT_REPORT: "v1/admin/student/attendance/reports",
    ATTENDANCE_MONTHLY_REPORT: "v1/admin/student/attendance/monthly-report",
    STUDENT_SMS_LOGS: "v1/admin/student/attendance/sms-logs",
    TODAY_FULL_ABSENT_COUNT: "v1/admin/student/attendance/full-absent-count",
    COURSE_LACK_REPORT: "v1/admin/student/attendance/course-lack-report",
    SEND_SMS_ABSENTES_MANUALLY:
      "v1/admin/student/attendance/send-sms-to-absentees",

    ALLOCATED_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocated-list",
    ALLOCATE_CLASS_WISE_SUBJECTS: "v1/admin/student/subject/allocate",
    STUDENTS_SUBJECT_LIST: "v1/admin/student/subject/students-allocated-list",
    DELETE_STUDENT_SUBJECT: "v1/admin/student/subject/delete-student-subject",
    FIND_COURSE_SUBJECT: "v1/admin/student/subject/find",
    UPDATE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/change-subject",
    SAVE_STUDENT_ALLOCATED_SUBJECT: "v1/admin/student/subject/add-subject",
    DELETE_ALLOCATED_SUBJECTS: "v1/admin/student/subject/delete-allocated",

    REMOVE_LEFT: "v1/admin/student/delete-left",
    UPDATE_LEFT: "v1/admin/student/update-left",

    SAVE_HOSTEL_ADMISSION: "v1/admin/hostel/admission/save",
    LIST_HOSTEL_STUDENTS: "v1/admin/hostel/admission/list",
    DELETE_HOSTEL_STUDENT: "v1/admin/hostel/admission/delete",
    UPDATE_HOSTEL_STUDENT: "v1/admin/hostel/admission/update",
    SAVE_BULK_HOSTEL_ADMISSION: "v1/admin/hostel/admission/bulk-save",

    NEW_STUDENT_ADMISSION: "v1/admin/student/admission",
    REQUEST: {
      LIST: "v1/admin/student/service_request/list-all",
      EDIT: "v1/admin/student/service_request/edit-request",
      LIST_STATUS: "v1/admin/student/service_request/list-request-status?id=",
    },
    //attendance routes added by karan
    CLASS_TAKEN_REPORT: "v1/admin/stud-attendance/class-taken-report",
    TIMETABLE_ON_DATE: "v1/admin/stud-attendance/today-time-table",
  },
  SETTINGS: {
    SAVE_CASHBOOKS: "v1/admin/cashbooks/save",
    LIST_CASHBOOKS: "v1/admin/cashbooks/list",
    DELETE_CASHBOOK: "v1/admin/cashbooks/delete",
    UPDATE_CASHBOOK: "v1/admin/cashbooks/update",

    SAVE_GATEWAY_MASTER: "v1/admin/gateways/save",
    LIST_GATEWAY_MASTER: "v1/admin/gateways/list",
    DELETE_GATEWAY_MASTER: "v1/admin/gateways/delete",
    UPDATE_GATEWAY_MASTER: "v1/admin/gateways/update",

    SAVE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/save",
    LIST_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/list",
    DELETE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/delete",
    UPDATE_GATEWAY_SUBACCOUNT: "v1/admin/gateway/sub/update",

    SAVE_COLLEGE_YEAR: "v1/admin/collegeyear/save",
    LIST_COLLEGE_YEAR: "v1/admin/collegeyear/list",
    DELETE_COLLEGE_YEAR: "v1/admin/collegeyear/delete",
    UPDATE_COLLEGE_YEAR: "v1/admin/collegeyear/update",

    SAVE_USERROLE: "v1/admin/rbac/userrole/save",
    LIST_USERROLE: "v1/admin/rbac/userrole/list",
    DELETE_USERROLE: "v1/admin/rbac/userrole/delete",
    UPDATE_USERROLE: "v1/admin/rbac/userrole/update",

    SAVE_ROLE_MODULE: "v1/admin/rbac/roleaccess/save-module",
    DELETE_ROLE_MODULE: "v1/admin/rbac/roleaccess/delete-module",
    UPDATE_ROLE_MODULE: "v1/admin/rbac/roleaccess/update-module-access",
    UPDATE_USER_ACCESS: "v1/admin/rbac/roleaccess/update-user-access",
    LIST_ROLE_ACCESS: "v1/admin/rbac/roleaccess/access-list",
    UPDATE_ROLE_ACCESS: "v1/admin/rbac/roleaccess/update-role-access",
    LIST_ROLE_PERMISSIONS: "v1/admin/rbac/roleaccess/list-role-permissions",

    SAVE_BATCH: "v1/admin/settings/batch/save",
    LIST_BATCH: "v1/admin/settings/batch/list",
    DELETE_BATCH: "v1/admin/settings/batch/delete",
    UPDATE_BATCH: "v1/admin/settings/batch/update",
    UPDATE_SEMESTER_DATES: "v1/admin/settings/batch/update-semester-dates",
    SEMESTER_DATES: "v1/admin/settings/batch/semester-dates",
    COLLEGE_YEARS: "v1/admin/settings/batch/list-college-years",

    SAVE_DEPARTMENT: "v1/admin/settings/departments/save",
    LIST_DEPARTMENT: "v1/admin/settings/departments/list",
    DELETE_DEPARTMENT: "v1/admin/settings/departments/delete",
    UPDATE_DEPARTMENT: "v1/admin/settings/departments/update",
    UPDATE_DEPARTMENT_COURSE:
      "v1/admin/settings/departments/update-dept-course",

    SAVE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/save",
    LIST_SUBJECT_NATURE: "v1/admin/settings/subjectnature/list",
    REMOVE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/delete",
    UPDATE_SUBJECT_NATURE: "v1/admin/settings/subjectnature/update",

    SAVE_SUBJECT: "v1/admin/settings/subjects/save",
    LIST_SUBJECT: "v1/admin/settings/subjects/list",
    DELETE_SUBJECT: "v1/admin/settings/subjects/delete",
    UPDATE_SUBJECT: "v1/admin/settings/subjects/update",

    SAVE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/save",
    LIST_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/list",
    DELETE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/delete",
    UPDATE_COURSE_SUBJECT: "v1/admin/settings/courseSubjects/update",
    SAVE_STAFFSUBJECT: "v1/admin/settings/staffsubject/save",
    LIST_STAFFSUBJECT: "v1/admin/settings/staffsubject/list",
    DELETE_STAFFSUBJECT: "v1/admin/settings/staffsubject/delete",
    UPDATE_STAFFSUBJECT: "v1/admin/settings/staffsubject/update",

    SAVE_TIMETABLE: "v1/admin/settings/timetable/save-time-table",
    UPDATE_TIMETABLE: "v1/admin/settings/timetable/update-time-table",
    GET_TIMETABLE: "v1/admin/settings/timetable/get-timetable",
    REMOVE_TIMETABLE: "v1/admin/settings/timetable/remove",

    LIST_COMMUNITY: "v1/set/community",
    LIST_RELIGIONS: "v1/set/religions",

    LIST_USER_LOGS: "v1/admin/logs/list-all",

    LIST_CLASS_TIME: "v1/admin/classtime/list-class-time",
    ADD_CLASS_TIME: "v1/admin/classtime/add-class-time",
    UPDATE_CLASS_TIME: "v1/admin/classtime/update-class-time",
  },
  FEE_CATEGORY: {
    SAVE_CATEGORY: "v1/admin/fees/category/save",
    LIST_CATEGORY: "v1/admin/fees/category/list",
    DELETE_CATEGORY: "v1/admin/fees/category/delete",
    UPDATE_CATEGORY: "v1/admin/fees/category/update",

    SAVE_FEE_GROUP: "v1/admin/fees/group/save",
    LIST_FEE_GROUP: "v1/admin/fees/group/list",
    DELETE_FEE_GROUP: "v1/admin/fees/group/delete",
    UPDATE_FEE_GROUP: "v1/admin/fees/group/update",
    UPDATE_FEE_GROUP_CATEGORY_LIST: "v1/admin/fees/group/update-list-id",

    SAVE_PAYMENT_METHOD: "v1/admin/fees/payment_method/save",
    LIST_PAYMENT_METHOD: "v1/admin/fees/payment_method/list",
    DELETE_PAYMENT_METHOD: "v1/admin/fees/payment_method/delete",
    UPDATE_PAYMENT_METHOD: "v1/admin/fees/payment_method/update",

    CATEGORY_LIST_WITH_STUDENT_COUNT:
      "v1/admin/fees/assign/category-with-student-count",
    SAVE_COURSE_WISE_FEE: "v1/admin/fees/assign/save-course-wise-fee",
    SAVE_STUDENT_WISE_FEE: "v1/admin/fees/assign/save-student-wise-fee",
    SAVE_STUDENT_WISE_FEE_V2: "v1/admin/fees/assign/save-student-wise-feev2",

    SAVE_COURSE_WISE_FEE_ADJUST: "v1/admin/fees/assign/save-course-wise-adjust",
    SAVE_STUDENT_WISE_FEE_ADJUST:
      "v1/admin/fees/assign/save-student-wise-adjust",
  },
  FEES: {
    BILL_NO: "v1/admin/fees/payment/bill-no",
    LIST_PAYMENT: "v1/admin/fees/payment/student-bills",
    SAVE_STUDENT_PAYMENT: "v1/admin/fees/payment/save-student-payment",
    LIST_STUDENT_PAYMENT: "v1/admin/fees/payment/list-bills",
    VIEW_STUDENT_BILL: "v1/admin/fees/payment/get-bill",
    DELETE_ASSIGNED_FEE: "v1/admin/fees/payment/delete-fee-record",
    BULK_DELETE_ASSIGNED_FEE: "v1/admin/fees/payment/delete-bulk-fee-record",
    UPDATE_PAID_PAYMENT_DATE: "v1/admin/fees/payment/update-bill-date",

    CHALLAN_BILL_NO: "v1/admin/fees/challan/bill-no",
    CHALLAN_LIST_PAYMENT: "v1/admin/fees/challan/student-bills",
    CHALLAN_SAVE_STUDENT_PAYMENT: "v1/admin/fees/challan/save-student-payment",
    CHALLAN_LIST_STUDENT_PAYMENT: "v1/admin/fees/challan/list-bills",
    CHALLAN_VIEW_STUDENT_BILL: "v1/admin/fees/challan/get-bill",
    CHALLAN_DELETE_ASSIGNED_FEE: "v1/admin/fees/challan/delete-fee-record",

    FEE_CATEGORY_REPORT: "v1/admin/fees/reports/category-reoprt",
    FEE_CATEGORY_SUMMARY: "v1/admin/fees/reports/category-summary",
    CLASS_WISE_PENDING: "v1/admin/fees/reports/class-wise-pending",
    CUSTOM_FEE_REPORT: "v1/admin/fees/reports/custom-fee-report",
    CUSTOM_FEE_CATEGORY_REPORT: "v1/admin/fees/reports/custom-category-reoprt",

    LIST_TEMPLATES: "v1/admin/fees/template/list-all",
    REMOVE_TEMPLATE: "v1/admin/fees/template/remove-data",
    SAVE_TEMPLATE: "v1/admin/fees/template/save-data",
    UPDATE_TEMPLATE: "v1/admin/fees/template/update-data",
    SAVE_CUSTOM_FEE_TEMPLATE: "v1/admin/fees/template/save-custom-fee",
    LIST_CUSTOM_FEE_TEMPLATES: "v1/admin/fees/template/list-custom-fees",

    CANCEL_BILL: "v1/admin/fees/payment/cancel-bill",

    SAVE_BANK: "v1/admin/fees/bank/save",
    LIST_BANKS: "v1/admin/fees/bank/list",
    DELETE_BANK: "v1/admin/fees/bank/delete",
    UPDATE_BANK: "v1/admin/fees/bank/update",

    SAVE_FINE: "v1/admin/fees/fine/save",
    LIST_FINE_LOGS: "v1/admin/fees/fine/list",

    SAVE_HOSTEL_FEE_IMPORT: "v1/admin/hostel/fee/save-import",

    ONLINE_PAY_LIST: "v1/admin/fees/onlinepay/list",
    ONLINE_PAY_LIST_DELETE: "v1/admin/fees/onlinepay/delete",
    ONLINE_PAY_TRACK_STATUS: "v1/admin/fees/onlinepay/track-order-status",
    ONLINE_PAY_TRACK_SPLIT_STATUS: "v1/admin/fees/onlinepay/track-split-status",
    ONLINE_PAY_UPDATE_REF_NO: "v1/admin/fees/onlinepay/update-refno",

    SAVE_CREDIT: "v1/admin/fees/credit/save",
    LIST_CREDIT: "v1/admin/fees/credit/list",
    DELETE_CREDIT: "v1/admin/fees/credit/delete",
  },
  RECEIPT_PAYMENTS: {
    SAVE_RECPAY: "v1/admin/fees/recpay/save",
    LIST_RECPAY: "v1/admin/fees/recpay/list-bills",
    GET_RECPAY_BILL: "v1/admin/fees/recpay/get-bill",
    CANCEL_RECPAY_BILL: "v1/admin/fees/recpay/cancel-bill",

    SAVE_RECPAY_CHALLAN: "v1/admin/fees/recchallan/save",
    LIST_RECPAY_CHALLAN: "v1/admin/fees/recchallan/list-bills",
    GET_RECPAY_BILL_CHALLAN: "v1/admin/fees/recchallan/get-bill",
    CANCEL_RECPAY_BILL_CHALLAN: "v1/admin/fees/recchallan/cancel-bill",
  },
  TRANSPORT: {
    SAVE_VEHICLE: "v1/admin/vehicle/transport/save-data",
    LIST_VEHICLES: "v1/admin/vehicle/transport/list-data",
    REMOVE_VEHICLE: "v1/admin/vehicle/transport/remove-data",
    UPDATE_VEHICLE: "v1/admin/vehicle/transport/update-data",

    SAVE_DESTINATION: "v1/admin/vehicle/destination/save-data",
    LIST_DESTINATION: "v1/admin/vehicle/destination/list-data",
    REMOVE_DESTINATION: "v1/admin/vehicle/destination/remove-data",
    UPDATE_DESTINATION: "v1/admin/vehicle/destination/update-data",
  },
  PAYROLL: {
    SAVE_LEAVE: "v1/admin/payroll.save_leave",
    LIST_EMPLOYEES_LEAVES: "v1/admin/payroll.list_data",
    UPDATE_EMPLOYEES_LEAVE: "v1/admin/payroll.update_data",
    REMOVE_EMPLOYEES_LEAVE: "v1/admin/payroll.remove_data",

    SAVE_PERMISSION: "v1/admin/payroll.save_permission",
    LIST_EMPLOYEES_PERMISSION: "v1/admin/payroll.list_data_permission",
    UPDATE_EMPLOYEES_PERMISSION: "v1/admin/payroll.update_permission",
    REMOVE_EMPLOYEES_PERMISSION: "v1/admin/payroll.remove_permission",

    LIST_BREAKUP_HEADS: "v1/admin/payroll.list_breakup_heads",
    SAVE_HEAD: "v1/admin/payroll.save_head",
    UPDATE_HEAD: "v1/admin/payroll.update_head",
    REMOVE_HEAD: "v1/admin/payroll.remove_head",
    GET_BREAK_HEAD: "v1/admin/payroll.get_breakhead",

    SAVE_GRADE: "v1/admin/payroll.save_data_setup",
    LIST_SETUP: "v1/admin/payroll.list_data_setup",
    UPDATE_SETUP: "v1/admin/payroll.update_data_setup",
    REMOVE_SETUP: "v1/admin/payroll.remove_data_setup",

    SAVE_SALARY: "v1/admin/payroll.save_salary",
    LIST_SALARY: "v1/admin/payroll.salary_list",
    LIST_SALARY_SETTINGS: "v1/admin/payroll.salary_settings_list",
    LIST_SALARY_DATA: "v1/admin/payroll.salary_list_data",

    LIST_EMPLOYEE_SALARY_DETAILS: "v1/admin/payroll.employee_salary_details",
    EMPLOYEE_SALARY_SETTINGS: "v1/admin/payroll.employee_salary_settings",

    SAVE_MONTH_SALARY: "v1/admin/payroll.save_employee_month_salary",
    LIST_BIOMETRIC_SUMMARY: "v1/admin/payroll.get_monthly_summary",
    LIST_DEVICELOG: "v1/admin/payroll.device_log_month",
    UPDATE_PAYROLL_ALLOWANCE: "v1/admin/payroll.payroll_allowance_update",
    EMPLOYEES_LEAVE_PERMISSION: "v1/admin/payroll.permission_leave_count",
    SAVE_RULES: "v1/admin/payroll.save_rules",
    LIST_RULES: "v1/admin/payroll.list_rules",
    PAYROLL_REPORTS: "v1/admin/payroll.payroll_reports",
  },
  HR: {
    SAVE_GRADE: "v1/admin/hr.save_grade",
    LIST_GRADES: "v1/admin/hr.list_gardes",
    REMOVE_GRADE: "v1/admin/hr.remove_grade",
    UPDATE_GRADE: "v1/admin/hr.update_grade",
    SAVE_BREAKUP_HEAD: "v1/admin/hr.save_breakuphead",
    LIST_BREAKUP_HEADS: "v1/admin/hr.list_breakuphead",
    REMOVE_BREAKUP_HEAD: "v1/admin/hr.remove_breakuphead",
    UPDATE_BREAKUP_HEAD: "v1/admin/hr.update_breakuphead",
    EMPLOYEE_NEW_CODE: "v1/admin/hr.employee_code",
    SAVE_EMPLOYEE: "v1/admin/hr.employee_save",
    LIST_EMPLOYEES: "v1/admin/hr.employees",
    REMOVE_EMPLOYEE: "v1/admin/hr.employee_remove",
    CHANGE_EMPLOYEE_STATUS: "v1/admin/hr.status_chagne",
    UPDATE_EMPLOYEE: "v1/admin/hr.employee_update",
    UPDATE_EMPLOYEE_SALARY: "v1/admin/hr.employee_update_salary",
    EMPLOYEE_BY_CODE: "v1/admin/hr.employee_by_code",
    SAVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_save",
    LIST_BIOMETRIC_DEVICES: "v1/admin/hr.device_list",
    REMOVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_remove",
    UPDATE_BIOMETRIC_DEVICE: "v1/admin/hr.device_update",
    LIST_LEAVE: "v1/admin/hr.leave_list",
    SAVE_HOLIDAY: "v1/admin/hr.holiday_save",
    LIST_HOLIDAYS: "v1/admin/hr.holiday_list",
    REMOVE_HOLIDAY: "v1/admin/hr.holiday_remove",
    SAVE_SUNDAYS: "v1/admin/hr.save_sundays",
    ATT_MONTH_LOG: "v1/admin/hr.att_month_log",
    SAVE_BRANCH: "v1/admin/hr.branch/save-data",
    LIST_BRANCHES: "v1/admin/hr.branch/list-data",
    REMOVE_BRANCHE: "v1/admin/hr.branch/remove-data",
    UPDATE_BRANCHE: "v1/admin/hr.branch/update-data",
    SAVE_DEPARTMENT: "v1/admin/hr.department/save-data",
    LIST_DEPARTMENTS: "v1/admin/hr.department/list-data",
    LIST_STDEPARTMENTS: "v1/admin/student_dept/list-data",
    REMOVE_DEPARTMENT: "v1/admin/hr.department/remove-data",
    UPDATE_DEPARTMENT: "v1/admin/hr.department/update-data",

    SAVE_TYPE: "v1/admin/hr.type/save-data",
    LIST_TYPE: "v1/admin/hr.type/list-data",
    REMOVE_TYPE: "v1/admin/hr.type/remove-data",
    UPDATE_TYPE: "v1/admin/hr.type/update-data",

    SAVE_LEAVE_TYPE: "v1/admin/hr.leavetype/save-data",
    LIST_LEAVE_TYPE: "v1/admin/hr.leavetype/list-data",
    REMOVE_LEAVE_TYPE: "v1/admin/hr.leavetype/remove-data",
    UPDATE_LEAVE_TYPE: "v1/admin/hr.leavetype/update-data",
    EMPLOYEE_LEAVE_LIST: "v1/admin/hr.leavetype/get-employee-allowedleaves",

    SAVE_DESIGNATION: "v1/admin/hr.designation/save-data",
    LIST_DESIGNATIONS: "v1/admin/hr.designation/list-data",
    REMOVE_DESIGNATION: "v1/admin/hr.designation/remove-data",
    UPDATE_DESIGNATION: "v1/admin/hr.designation/update-data",

    SAVE_SHIFT: "v1/admin/hr.shift_save",
    LIST_SHIFT: "v1/admin/hr.shift_list",
    UPDATE_SHIFT: "v1/admin/hr.shift_update",
    REMOVE_SHIFT: "v1/admin/hr.shift_remove",

    LAST_DEVICEID: "v1/admin/hr.last_inserted_deviceid",
    INSERT_DEVICE_DATAS: "v1/admin/hr.device_monthly_data",

    SAVE_ATTENDANCE: "v1/admin/hr.attendance/save-data",
    LIST_ATTENDANCE: "v1/admin/hr.attendance/list-data",
    REMOVE_ATTENDANCE: "v1/admin/hr.attendance/remove-data",
    UPDATE_ATTENDANCE: "v1/admin/hr.attendance/update-data",
  },
  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
    REMOVE: "v1/field_settings/delete",
    UPDATE: "v1/field_settings/update",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/balance",
  },
  EVENTS: {
    SAVE_REPORTS: "v1/admin/events/save-reports",
    LIST_EVENTS_REPORT: "v1/admin/events/list",
    UPDATE_REPORTS: "v1/admin/events/update",
    REMOVE_REPORTS: "v1/admin/events/delete",
  },
  CIRCULAR: {
    SAVE_COLLEGE_CIRCULAR: "v1/admin/circular/college/save-circular",
    LIST_COLLEGE_CIRCULAR: "v1/admin/circular/college/list",
    UPDATE_COLLEGE_CIRCULAR: "v1/admin/circular/college/update",
    REMOVE_COLLEGE_CIRCULAR: "v1/admin/circular/college/delete",
    SAVE_OTHER_CIRCULAR: "v1/admin/circular/other/save-circular",
    LIST_OTHER_CIRCULAR: "v1/admin/circular/other/list",
    UPDATE_OTHER_CIRCULAR: "v1/admin/circular/other/update",
    REMOVE_OTHER_CIRCULAR: "v1/admin/circular/other/delete",
  },
  FACULTY: {
    LIST_ACTIVITIES: "v1/admin/faculty/list",
    SAVE_ACTIVITIES: "v1/admin/faculty/save",
    UPDATE_ACTIVITIES: "v1/admin/faculty/update",
    REMOVE_ACTIVITIES: "v1/admin/faculty/delete",
  },
  AWARD: {
    LIST_AWARDS: "v1/admin/awards/list",
    SAVE_AWARDS: "v1/admin/awards/save",
    UPDATE_AWARDS: "v1/admin/awards/update",
    REMOVE_AWARDS: "v1/admin/awards/delete",
  },
  WEBSITE_CMS: {
    SAVE_PAGE: "v1/admin/cms/page/save",
    LIST_PAGES: "v1/admin/cms/page/list",
    DELETE_PAGE: "v1/admin/cms/page/delete",
    GET_PAGE: "v1/admin/cms/page/get",
    UPDATE_PAGE: "v1/admin/cms/page/update",
    GET_EVENT_CATEGORY: "v1/admin/cms/page/get-event-category",

    SAVE_GALLERY: "v1/admin/cms/page/save-gallery",
    LIST_GALLERIES: "v1/admin/cms/page/list-galleries",
    DELETE_GALLERY: "v1/admin/cms/page/delete-gallery",
    LIST_GALLERY: "v1/admin/cms/page/list-gallery",
    UPDATE_GALLERY: "v1/admin/cms/page/update-gallery",

    LIST_CATEGORY_NAMES: "v1/admin/cms/page/list-categories",

    FILES_LIST_DIRECTORY: "v1/admin/files/list-directory-v2",
    FILES_UPLOAD: "v1/admin/files/upload-files-v2",
    REMOVE_FILE: "v1/admin/files/remove-v2",
    CREATE_FOLDER: "v1/admin/files/create-folder-v2",
  },
  DEPT_CMS: {
    SAVE_SLIDER: "v1/admin/deptcms/slider/save",
    LIST_SLIDERS: "v1/admin/deptcms/slider/list",
    DELETE_SLIDER: "v1/admin/deptcms/slider/delete",

    SAVE_PAGE: "v1/admin/deptcms/page/save",
    GET_PAGE: "v1/admin/deptcms/page/get",

    SAVE_FILE: "v1/admin/deptcms/file/save",
    LIST_FILES: "v1/admin/deptcms/file/list",
    DELETE_FILE: "v1/admin/deptcms/file/remove",
    UPDATE_FILE: "v1/admin/deptcms/file/update",
  },
  NAAC: {
    SAVE_CRITERIA: "v1/admin/naac/save-criteria",
    LIST_CRITERIA: "v1/admin/naac/list-criteria",
    REMOVE_CRITERIA: "v1/admin/naac/remove-criteria",
    UPDATE_CRITERIA: "v1/admin/naac/update-criteria",

    SAVE_CRITERIA_GROUP: "v1/admin/naac/save-criteria-group",
    LIST_CRITERIA_GROUP: "v1/admin/naac/list-criteria-group",
    REMOVE_CRITERIA_GROUP: "v1/admin/naac/remove-criteria-group",
    UPDATE_CRITERIA_GROUP: "v1/admin/naac/update-criteria-group",

    SAVE_REPORT: "v1/admin/naac/save-report",
    LIST_REPORTS: "v1/admin/naac/list-reports",
    REMOVE_REPORT: "v1/admin/naac/remove-report",
    GET_REPORT: "v1/admin/naac/get-report",
    UPDATE_REPORT: "v1/admin/naac/update-report",
  },
  UTILITIES: {
    LIST_STUDENT_CERTIFICATES: "v1/admin/util/stucert/list-all",
    GET_STUDENT_CERTIFICATE: "v1/admin/util/stucert/get",
    UPDATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/update",
    CREATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/save",

    SAVE_DAY_ORDER: "v1/admin/util/attdayorder/save",
    LIST_DAY_ORDERS: "v1/admin/util/attdayorder/list",
    DELETE_DAY_ORDER: "v1/admin/util/attdayorder/delete",
    UPDATE_DAY_ORDER: "v1/admin/util/attdayorder/update",
    DAY_ORDER_BY_BATCH: "v1/admin/util/attdayorder/by-batch",

    SAVE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/save",
    LIST_ATTENDANCE_PERCENTAGES: "v1/admin/util/attpercentage/list",
    DELETE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/delete",
    UPDATE_ATTENDANCE_PERCENTAGE: "v1/admin/util/attpercentage/update",

    SAVE_SCHOLARSHIPS: "v1/admin/util/scholarship/save",
    LIST_SCHOLARSHIPS: "v1/admin/util/scholarship/list-all",
    DELETE_SCHOLARSHIPS: "v1/admin/util/scholarship/delete",
    UPDATE_SCHOLARSHIPS: "v1/admin/util/scholarship/update",
  },
  MOB: {
    CHECK_USERNAME: "mob/v1/login",
    VERIFY_LOGIN_OTP: "mob/v1/verify_otp",
    SET_LOGIN_PASSWORD: "mob/v1/set_password",
    MY_TIME_TABLE: "mob/v1/timetable/my-time-table",
    SEARCH_STUDENT: "mob/v1/students/search",
    SAVE_STUDENT_ATTENDANCE: "mob/v1/timetable/save",
    LIST_STUDENTS_ATTENDANCE: "mob/v1/timetable/list-attendance",
    CURRENT_SEM_DATE: "mob/v1/timetable/current-sem-date",
    LIST_LONG_ABSENTEE: "mob/v1/students/long-absentee",
  },
  //service urls for exam by karan
  EXAMS: {
    //templates
    ADD_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/add-template-exam-master",
    UPDATE_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/update-template-exam-master",
    TOTAL_RECORDS_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/total-records-template-exam-master",
    LIST_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/list-template-exam-master",
    DELETE_TEMPLATES_EXAM_MASTER:
      "v1/admin/exams/examtemplate/delete-template-exam-master",

    ADD_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/add-template-exam-type",
    UPDATE_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/update-template-exam-type",
    TOTAL_RECORDS_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/total-records-template-exam-type",
    LIST_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/list-template-exam-type",
    DELETE_TEMPLATE_EXAM_TYPE:
      "v1/admin/exams/examtemplate/delete-template-exam-type",

    ADD_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/add-template-exam-type-categorization",
    UPDATE_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/update-template-exam-type-categorization",
    TOTAL_RECORDS_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/total-records-template-exam-type-categorization",
    LIST_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/list-template-exam-type-categorization",
    DELETE_TEMPLATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examtemplate/delete-template-exam-type-categorization",

    ADD_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/add-template-consolidation",
    UPDATE_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/update-template-consolidation",
    TOTAL_RECORDS_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/total-records-template-consolidation",
    LIST_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/list-template-consolidation",
    DELETE_TEMPLATE_CONSOLIDATION:
      "v1/admin/exams/examtemplate/delete-template-consolidation",

    ADD_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/add-template-consolidation-prop",
    UPDATE_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/update-template-consolidation-prop",
    TOTAL_RECORDS_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/total-records-template-consolidation-prop",
    LIST_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/list-template-consolidation-prop",
    DELETE_TEMPLATE_CONSOLIDATION_PROP:
      "v1/admin/exams/examtemplate/delete-template-consolidation-prop",

    LIST_ALL_TEMPLATE_EXAM_TYPES_AND_CATEGORIZATION:
      "v1/admin/exams/examtemplate/list-all-template-exam-types-and-categorization",
    LIST_ALL_TEMPLATE_CONSOLIDATIONS_AND_CONSOLIDATION_PROPS:
      "v1/admin/exams/examtemplate/list-all-template-consolidations-and-consolidation-props",

    //config
    ADD_EXAM: "v1/admin/exams/examconfig/add-exam",
    UPDATE_EXAM: "v1/admin/exams/examconfig/update-exam",
    TOTAL_RECORDS_EXAM: "v1/admin/exams/examconfig/total-records-exam",
    LIST_EXAM: "v1/admin/exams/examconfig/list-exam",
    DELETE_EXAM: "v1/admin/exams/examconfig/delete-exam",

    ADD_EXAM_TYPE: "v1/admin/exams/examconfig/add-exam-type",
    UPDATE_EXAM_TYPE: "v1/admin/exams/examconfig/update-exam-type",
    TOTAL_RECORDS_EXAM_TYPE:
      "v1/admin/exams/examconfig/total-records-exam-type",
    LIST_EXAM_TYPE: "v1/admin/exams/examconfig/list-exam-type",
    DELETE_EXAM_TYPE: "v1/admin/exams/examconfig/delete-exam-type",

    ADD_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/add-exam-type-categorization",
    UPDATE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/update-exam-type-categorization",
    TOTAL_RECORDS_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/total-records-exam-type-categorization",
    LIST_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/list-exam-type-categorization",
    DELETE_EXAM_TYPE_CATEGORIZATION:
      "v1/admin/exams/examconfig/delete-exam-type-categorization",

    ADD_EXAM_CLASS: "v1/admin/exams/examconfig/add-exam-class",
    UPDATE_EXAM_CLASS: "v1/admin/exams/examconfig/update-exam-class",
    TOTAL_RECORDS_EXAM_CLASS:
      "v1/admin/exams/examconfig/total-records-exam-class",
    LIST_EXAM_CLASS: "v1/admin/exams/examconfig/list-exam-class",
    DELETE_EXAM_CLASS: "v1/admin/exams/examconfig/delete-exam-class",

    ADD_EXAM_TIME_TABLE: "v1/admin/exams/examconfig/add-exam-time-table",
    UPDATE_EXAM_TIME_TABLE: "v1/admin/exams/examconfig/update-exam-time-table",
    TOTAL_RECORDS_EXAM_TIME_TABLE:
      "v1/admin/exams/examconfig/total-records-exam-time-table",
    LIST_EXAM_TIME_TABLE: "v1/admin/exams/examconfig/list-exam-time-table",
    DELETE_EXAM_TIME_TABLE: "v1/admin/exams/examconfig/delete-exam-time-table",

    ADD_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/add-cos-exam-class-subject",
    UPDATE_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/update-cos-exam-class-subject",
    TOTAL_RECORDS_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/total-records-cos-exam-class-subject",
    LIST_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/list-cos-exam-class-subject",
    DELETE_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/delete-cos-exam-class-subject",

    ADD_EXAM_CONSOLIDATION: "v1/admin/exams/examconfig/add-exam-consolidation",
    UPDATE_EXAM_CONSOLIDATION:
      "v1/admin/exams/examconfig/update-exam-consolidation",
    TOTAL_RECORDS_EXAM_CONSOLIDATION:
      "v1/admin/exams/examconfig/total-records-exam-consolidation",
    LIST_EXAM_CONSOLIDATION:
      "v1/admin/exams/examconfig/list-exam-consolidation",
    DELETE_EXAM_CONSOLIDATION:
      "v1/admin/exams/examconfig/delete-exam-consolidation",

    ADD_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/add-exam-consolidation-properties",
    UPDATE_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/update-exam-consolidation-properties",
    TOTAL_RECORDS_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/total-records-exam-consolidation-properties",
    LIST_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/list-exam-consolidation-properties",
    DELETE_EXAM_CONSOLIDATION_PROPERTIES:
      "v1/admin/exams/examconfig/delete-exam-consolidation-properties",

    ADD_EXAM_GRADE: "v1/admin/exams/examconfig/add-exam-grade",
    UPDATE_EXAM_GRADE: "v1/admin/exams/examconfig/update-exam-grade",
    TOTAL_RECORDS_EXAM_GRADE:
      "v1/admin/exams/examconfig/total-records-exam-grade",
    LIST_EXAM_GRADE: "v1/admin/exams/examconfig/list-exam-grade",
    DELETE_EXAM_GRADE: "v1/admin/exams/examconfig/delete-exam-grade",

    ADD_COK_PATTERN: "v1/admin/exams/examconfig/add-cok-pattern",
    UPDATE_COK_PATTERN: "v1/admin/exams/examconfig/update-cok-pattern",
    LIST_COK_PATTERN: "v1/admin/exams/examconfig/list-cok-pattern",
    DELETE_COK_PATTERN: "v1/admin/exams/examconfig/delete-cok-pattern",
    ADD_COK_SCALING: "v1/admin/exams/examconfig/add-cok-scaling",
    UPDATE_COK_SCALING: "v1/admin/exams/examconfig/update-cok-scaling",
    LIST_COK_SCALING: "v1/admin/exams/examconfig/list-cok-scaling",
    DELETE_COK_SCALING: "v1/admin/exams/examconfig/delete-cok-scaling",

    ADD_EXAM_GRADE_SCALE: "v1/admin/exams/examconfig/add-exam-grade-scale",
    UPDATE_EXAM_GRADE_SCALE:
      "v1/admin/exams/examconfig/update-exam-grade-scale",
    TOTAL_RECORDS_EXAM_GRADE_SCALE:
      "v1/admin/exams/examconfig/total-records-exam-grade-scale",
    LIST_EXAM_GRADE_SCALE: "v1/admin/exams/examconfig/list-exam-grade-scale",
    DELETE_EXAM_GRADE_SCALE:
      "v1/admin/exams/examconfig/delete-exam-grade-scale",

    ADD_MARK_ENTRY_STAFF: "v1/admin/exams/examconfig/add-mark-entry-staff",
    UPDATE_MARK_ENTRY_STAFF:
      "v1/admin/exams/examconfig/update-mark-entry-staff",
    TOTAL_RECORDS_MARK_ENTRY_STAFF:
      "v1/admin/exams/examconfig/total-records-mark-entry-staff",
    LIST_MARK_ENTRY_STAFF: "v1/admin/exams/examconfig/list-mark-entry-staff",
    TOTAL_FILTER_RECORDS_MARK_ENTRY_STAFF:
      "v1/admin/exams/examconfig/total-filter-records-mark-entry-staff",
    DELETE_MARK_ENTRY_STAFF:
      "v1/admin/exams/examconfig/delete-mark-entry-staff",
    LIST_CLASS_WISE_STUDENTS_MARKS:
      "v1/admin/exams/examconfig/list-class-wise-students-marks",

    ADD_EXAM_SCHEDULE: "v1/admin/exams/examconfig/add-exam-schedule",
    UPDATE_EXAM_SCHEDULE: "v1/admin/exams/examconfig/update-exam-schedule",
    TOTAL_RECORDS_EXAM_SCHEDULE:
      "v1/admin/exams/examconfig/total-records-exam-schedule",
    LIST_EXAM_SCHEDULE: "v1/admin/exams/examconfig/list-exam-schedule",
    DELETE_EXAM_SCHEDULE: "v1/admin/exams/examconfig/delete-exam-schedule",

    GENERATE_EXAM_DEFAULTS: "v1/admin/exams/examconfig/generate-exam-defaults",
    ALL_SUBJECTS_IN_CLASS: "v1/admin/exams/examconfig/all-subjects-in-class",
    GENERATE_EXAM_CLASS_TIME_TABLE:
      "v1/admin/exams/examconfig/generate-exam-class-time-table",
    ALL_STUDENTS_IN_EXAM_SUBJECT:
      "v1/admin/exams/examconfig/all-students-in-exam-subject",
    ALL_ACADEMIC_DEPARTMENTS:
      "v1/admin/exams/examconfig/all-academic-departments",
    GENERATE_EXAM_SUBJECT_EXAM_TYPE_DEFAULT:
      "v1/admin/exams/examconfig/generate-exam-subject-exam-type-default",
    ADD_ALL_COS_EXAM_CLASS_SUBJECT:
      "v1/admin/exams/examconfig/add-all-cos-exam-class-subject",
    COPY_CO_IF_MATCHED_RECENT:
      "v1/admin/exams/examconfig/copy-co-if-matched-recent",
    DELETE_SUBJECT_MARKS_AND_CONFIGURATIONS:
      "v1/admin/exams/examconfig/delete-subject-marks-and-configurations",

    //marks
    ADD_QUESTION_MARKS: "v1/admin/exams/marks/add-question-marks",
    UPDATE_QUESTION_MARKS: "v1/admin/exams/marks/update-question-marks",
    TOTAL_RECORDS_QUESTION_MARKS:
      "v1/admin/exams/marks/total-records-question-marks",
    LIST_QUESTION_MARKS: "v1/admin/exams/marks/list-question-marks",
    DELETE_QUESTION_MARKS: "v1/admin/exams/marks/delete-question-marks",

    ADD_TOTAL_MARKS: "v1/admin/exams/marks/add-total-mark",
    UPDATE_TOTAL_MARKS: "v1/admin/exams/marks/update-total-mark",
    TOTAL_RECORDS_TOTAL_MARKS: "v1/admin/exams/marks/total-records-total-mark",
    LIST_TOTAL_MARKS: "v1/admin/exams/marks/list-total-mark",
    DELETE_TOTAL_MARKS: "v1/admin/exams/marks/delete-total-mark",

    ADD_UPDATE_ALL_STUDENT_MARKS:
      "v1/admin/exams/marks/add-update-all-student-marks",
    ADD_UPDATE_ALL_STUDENT_TOTAL_MARKS:
      "v1/admin/exams/marks/add-update-all-student-total-marks",
    LIST_MARKS_ENTRY_STATUS: "v1/admin/exams/marks/list-marks-entry-status",

    LIST_EXAM_SCHEDULE_AND_LOCK:
      "v1/admin/exams/examconfig/list-exam-schedule-and-lock",
    TOTAL_RECORDS_EXAM_TIME_TABLE_AND_LOCK:
      "v1/admin/exams/examconfig/total-records-exam-time-table-and-lock",
    // FILTER_LIST_EXAM_TIME_TABLE_AND_LOCK:
    //   "v1/admin/exams/examconfig/filter-list-exam-time-table-and-lock",
    ADD_UPDATE_EXAM_SCHEDULE:
      "v1/admin/exams/examconfig/add-update-exam-schedule",
    LIST_QUESTION_MARKS_ONLY: "v1/admin/exams/marks/list-question-marks-only",
    SUBMIT_CONSOLIDATION_MARKS:
      "v1/admin/exams/marks/submit-consolidation-marks",
    TOTAL_RECORDS_EXAM_STUDENT_ATTAINMENT:
      "v1/admin/exams/marks/total-records-exam-student-attainment",
    LIST_EXAM_STUDENT_ATTAINMENT:
      "v1/admin/exams/marks/list-exam-student-attainment",
  },
  //service urls for assets, visitor and letters

  ASSETS: {
    SAVE_COMPANY: "v1/admin/assets/save-company",
    LIST_COMPANY: "v1/admin/assets/list-company",
    UPDATE_COMPANY: "v1/admin/assets/update-company",
    SAVE_ASSET: "v1/admin/assets/save-asset",
    LIST_ASSETS: "v1/admin/assets/list-assets",
    VIEW_ASSETS: "v1/admin/assets/view-assets",
    REMOVE_ASSET: "v1/admin/assets/remove-asset",
    REMOVE_COMPANY: "v1/admin/assets/remove-company",
    UPDATE_ASSETS: "v1/admin/assets/update-assets",
    SAVE_DISTRIBUTION: "v1/admin/assets/save-distribution",
    LIST_DISTRIBUTION: "v1/admin/assets/list-distribution",
    UPDATE_DISTRIBUTION: "v1/admin/assets/update-distribution",
    REMOVE_DISTRIBUTION: "v1/admin/assets/remove-distribution",
    SAVE_RETURN: "v1/admin/assets/save-return",
    LIST_RETURN: "v1/admin/assets/list-return",
    GET_RETURN: "v1/admin/assets/get-return",
    SAVE_SERVICE_RECORD: "v1/admin/assets/save-service-record",
    LIST_SERVICE_RECORD: "v1/admin/assets/list-service-record",
    UPDATE_SERVICE_RECORD: "v1/admin/assets/update-service-record",
  },
  VISITOR: {
    SAVE_VISITOR: "v1/admin/visitors/save-visitor",
    LIST_VISITORS: "v1/admin/visitors/list-visitors",
    UPDATE_VISITOR: "v1/admin/visitors/update-visitor",
    REMOVE_VISITOR: "v1/admin/visitors/remove-visitor",
  },
  LETTERS: {
    SAVE_LETTER: "v1/admin/letters/save-letter",
    LIST_LETTERS: "v1/admin/letters/list-letters",
    UPDATE_LETTER: "v1/admin/letters/update-letter",
    REMOVE_LETTER: "v1/admin/letters/remove-letter",
    SAVE_LETTER_EXTERNAL: "v1/admin/letters/save-letter-external",
    LIST_LETTERS_EXTERNAL: "v1/admin/letters/list-letters-external",
    UPDATE_LETTER_EXTERNAL: "v1/admin/letters/update-letter-external",
    REMOVE_LETTER_EXTERNAL: "v1/admin/letters/remove-letter-external",
  },
  DISCIPLINARY: {
    SAVE_DISCIPLINARY: "v1/admin/disciplinaryAction/save",
    LIST_DISCIPLINARY: "v1/admin/disciplinaryAction/list",
    DELETE_DISCIPLINARY: "v1/admin/disciplinaryAction/delete?id=",
    UPDATE_DISCIPLINARY: "v1/admin/disciplinaryAction/update",
  },
  AWARDSTUDENTS: {
    SAVE_AWARDSTUDENTS: "v1/admin/awardsStudents/save",
    LIST_AWARDSTUDENTS: "v1/admin/awardsStudents/list",
    DELETE_AWARDSTUDENTS: "v1/admin/awardsStudents/delete?id=",
    UPDATE_AWARDSTUDENTS: "v1/admin/awardsStudents/update",
  },
  APPLICATIONS: {
    TOTAL_RECORDS_APPLICATION: "v1/admin/application/total-records-application",
    LIST_APPLICATION: "v1/admin/application/list-application",
  },

  //internship
  INTERNSHIP: {
    LIST_INTERNSHIP: "v1/admin/internship/list",
    SAVE_INTERNSHIP: "v1/admin/internship/save",
    UPDATE_INTERNSHIP: "v1/admin/internship/update",
    REMOVE_INTERNSHIP: "v1/admin/internship/delete",
  },

  //Feedbacks
  FEEDBACKS: {
    ADD_FEEDBACK_TITLE: "v1/admin/feedbacks/add-feedback-title",
    UPDATE_FEEDBACK_TITLE: "v1/admin/feedbacks/update-feedback-title",
    TOTAL_RECORDS_FEEDBACK_TITLE:
      "v1/admin/feedbacks/total-records-feedback-title",
    LIST_FEEDBACK_TITLE: "v1/admin/feedbacks/list-feedback-title",
    DELETE_FEEDBACK_TITLE: "v1/admin/feedbacks/delete-feedback-title",
    LIST_SEARCHFEEDBACK_TITLE: "v1/admin/feedbacks/list-feedbacksearch-title",
    UPDATE_FEEDBACK_Rolles: "v1/admin/feedbacks/update-feedback-rolles",
    LIST_FEEDBACK_COURSES: "v1/admin/feedbacks/list-feedback-courses",

    ADD_FEEDBACK_QUESTION: "v1/admin/feedbacks/add-feedback-question",
    UPDATE_FEEDBACK_QUESTION: "v1/admin/feedbacks/update-feedback-question",
    // LIST_FEEDBACK_TITLE: "v1/admin/feedbacks/list-feedback-title",
    // DELETE_FEEDBACK_TITLE: "v1/admin/feedbacks/delete-feedback-title",
    // LIST_SEARCHFEEDBACK_TITLE: "v1/admin/feedbacks/list-feedbacksearch-title",

    // ADD_FEEDBACK_QUESTION: "v1/admin/feedbacks/add-feedback-question",
    // UPDATE_FEEDBACK_QUESTION: "v1/admin/feedbacks/update-feedback-question",
    TOTAL_RECORDS_FEEDBACK_QUESTION:
      "v1/admin/feedbacks/total-records-feedback-question",
    LIST_FEEDBACK_QUESTION: "v1/admin/feedbacks/list-feedback-question",
    DELETE_FEEDBACK_QUESTION: "v1/admin/feedbacks/delete-feedback-question",

    ADD_FEEDBACK_OPTION: "v1/admin/feedbacks/add-feedback-option",
    UPDATE_FEEDBACK_OPTION: "v1/admin/feedbacks/update-feedback-option",
    TOTAL_RECORDS_FEEDBACK_OPTION:
      "v1/admin/feedbacks/total-records-feedback-option",
    LIST_FEEDBACK_OPTION: "v1/admin/feedbacks/list-feedback-option",
    DELETE_FEEDBACK_OPTION: "v1/admin/feedbacks/delete-feedback-option",

    ADD_FEEDBACK_ANSWER: "v1/admin/feedbacks/add-feedback-answer",
    UPDATE_FEEDBACK_ANSWER: "v1/admin/feedbacks/update-feedback-answer",
    TOTAL_RECORDS_FEEDBACK_ANSWER:
      "v1/admin/feedbacks/total-records-feedback-answer",
    LIST_FEEDBACK_ANSWER: "v1/admin/feedbacks/list-feedback-answer",
    DELETE_FEEDBACK_ANSWER: "v1/admin/feedbacks/delete-feedback-answer",
  },

  HELPCENTER: {
    ADD_PAGE: "v1/admin/helpcenter/add-page",
    LIST_CONTENTS: "v1/admin/helpcenter/list-pages",
    UPDATE_PAGE: "v1/admin/helpcenter/update-page",
    LIST_TITLES: "v1/admin/helpcenter/list-titles",
    LIST_PARENT_TITLES: "v1/admin/helpcenter/list-parent-titles",
    ADD_MENU: "v1/admin/helpcenter/add-menu",
    LIST_TOPICS: "v1/admin/helpcenter/list-topic",
  },

  OPAC: {
    OPAC_LIST_BOOK: "v1/admin/opac/list-items",
  },

  REQUESTTYPE: {
    SAVE_TYPE: "v1/admin/requesttype/savetype",
    LIST_TYPE: "v1/admin/requesttype/listype",
    UPDATE_TYPE: "v1/admin/requesttype/updatetype",
    REMOVE_TYPE: "v1/admin/requesttype/delete?id=",
    GET_PAGE: "v1/admin/requesttype/get",
    //REMOVE_COURSES: "v1/admin/courses/remove-course",
    LIST_FEEDBACK_ANSWER: "v1/admin/feedbacks/list-feedback-answer",
    DELETE_FEEDBACK_ANSWER: "v1/admin/feedbacks/delete-feedback-answer",
  },

  OFFICEDOCUMENT: {
    LIST_OFFICE: "v1/admin/office/list-all",
    SAVE_OFFICEDOCUMENT: "v1/admin/office/save",
  },
  LIBRARIES: {
    LIST_LIBRARIES: "v1/admin/libraries/list",
    SAVE_LIBRARIES: "v1/admin/libraries/save",
    UPDATE_LIBRARIES: "v1/admin/libraries/update",
    REMOVE_LIBRARIES: "v1/admin/libraries/delete",

    LIST_BOOK_TYPES: "v1/admin/libraries/types/list",
    SAVE_BOOK_TYPES: "v1/admin/libraries/types/save",
    UPDATE_BOOK_TYPES: "v1/admin/libraries/types/update",
    REMOVE_BOOK_TYPES: "v1/admin/libraries/types/delete",

    LIST_GROUPS: "v1/admin/libraries/groups/list",
    SAVE_GROUPS: "v1/admin/libraries/groups/save",
    UPDATE_GROUPS: "v1/admin/libraries/groups/update",
    REMOVE_GROUPS: "v1/admin/libraries/groups/delete",

    GET_ITEM_CONFIG: "v1/admin/libraries/book/item-config",
    GET_NEXT_ACCESS_NUMBER: "v1/admin/libraries/book/get-next-access-number",

    LIST_BOOK: "v1/admin/libraries/book/list",
    SAVE_BOOK: "v1/admin/libraries/book/save",
    UPDATE_BOOK: "v1/admin/libraries/book/update",
    REMOVE_BOOK: "v1/admin/libraries/book/delete",
    CALL_OTHER_API: "v1/admin/libraries/book/get-datafrom-api",
    SAVE_MARC_BOOK: "v1/admin/libraries/book/save-marc-book",
    GET_MARC_STRUCTURE: "v1/admin/libraries/book/get-marcstructure",
    GET_MARC_TAG_AND_SUBFIELDS:
      "v1/admin/libraries/book/get-marc-tag-and-subfields", //for new entryand edit form

    LIST_LOCATIONS: "v1/admin/libraries/locations/list",
    SAVE_LOCATIONS: "v1/admin/libraries/locations/save",
    UPDATE_LOCATIONS: "v1/admin/libraries/locations/update",
    REMOVE_LOCATIONS: "v1/admin/libraries/locations/delete",

    LIST_GENRE: "v1/admin/libraries/genre/list",
    SAVE_GENRE: "v1/admin/libraries/genre/save",
    UPDATE_GENRE: "v1/admin/libraries/genre/update",
    REMOVE_GENRE: "v1/admin/libraries/genre/delete",
    UPDATE_GENRE_ORDER: "v1/admin/libraries/genre/update-genre-order",
    LIST_SUBJECT_GENRE: "v1/admin/libraries/genre/subject-genre",
    LIST_LOCATION: "v1/admin/libraries/genre/location",

    LIST_MARC_TAG: "v1/admin/libraries/marc/tags",
    LIST_MARC_TAG_SUBFIELD: "v1/admin/libraries/marc/subfields",

    SAVE_FRAMESWORK: "v1/admin/libraries/marc/saveframe",
    UPDATE_FRAMESWORK: "v1/admin/libraries/marc/frame-update",
    DELETE_FRAMESWORK: "v1/admin/libraries/marc/frame-delete",
    DELETE_TAG_FRAMESWORK: "v1/admin/libraries/marc/frame-tag-delete",
    LIST_MARC_FRAMESWORK: "v1/admin/libraries/marc/list",
    LIST_MARC_CONFIGURATION_FRAMEWORK: "v1/admin/libraries/marc/configurelist",
    SAVE_FRAMESWORK_CONFIGURE: "v1/admin/libraries/marc/saveconfigure",
    SAVE_SUBTAG_FRAMESWORK: "v1/admin/libraries/marc/savesubtag",

    LIST_USER_CATEGORY: "v1/admin/libraries/user-category/list",
    SAVE_USER_CATEGORY: "v1/admin/libraries/user-category/save",
    UPDATE_USER_CATEGORY: "v1/admin/libraries/user-category/update",
    REMOVE_USER_CATEGORY: "v1/admin/libraries/user-category/delete",

    SEARCH_USER: "v1/admin/libraries/book-issue/search",
    SAVE_USER: "v1/admin/libraries/book-issue/save",

    LIST_ITEM_CIRCULATION: "v1/admin/libraries/item-circulation/list",
    CIRCULATION_COUNT: "v1/admin/libraries/item-circulation/circulation-count",
    LIST_ITEM_CIRCULATION_BY_ITEM:
      "v1/admin/libraries/item-circulation/list-by-item",
    SAVE_ITEM_CIRCULATION: "v1/admin/libraries/item-circulation/save",
    RENEW_ITEM: "v1/admin/libraries/item-circulation/renew",
    RETURN_ITEM: "v1/admin/libraries/item-circulation/return",
    LIST_ITEM: "v1/admin/libraries/item-circulation/list",

    SEARCH_BOOK: "v1/admin/libraries/library-item/search",

    VERIFY_USER_CATEGORY: "v1/admin/libraries/user-category/verify",

    //LAXMI-LIBRARY
    LIST_ITEM_BOOK: "v1/admin/libraries/barcode-print/item-list",
    CREATE_TEMPLATE_FILE: "v1/admin/libraries/barcode-print/create-template",
    UPDATE_TEMPLATE_FILE: "v1/admin/libraries/barcode-print/update-template",
    GET_TEMPLATE_CONTENT:
      "v1/admin/libraries/barcode-print/barcode-template-content",
    GET_BARCODE_IMAGE:
      "v1/admin/libraries/barcode-print/generate-barcode-image",

    SAVE_SERIALS: "v1/admin/libraries/library-serials/save",
    LIST_SERIALS: "v1/admin/libraries/library-serials/list",
    DELETE_SERIALS: "v1/admin/libraries/library-serials/delete",
    UPDATE_SERIALS: "v1/admin/libraries/library-serials/update",

    SAVE_READING_BOOK: "v1/admin/libraries/reader-corner/save",
    SAVE_READING_ATTENDANCE: "v1/admin/libraries/reader-corner/save-attendance",
    LIST_READING_BOOK: "v1/admin/libraries/reader-corner/list",
    UPDATE_READING_BOOK: "v1/admin/libraries/reader-corner/update",

    LIST_ATTENDANCE_REPORT: "v1/admin/libraries/attendance/list",
    LIST_ATTENDANCE: "v1/admin/libraries/attendance/attendance-list",
    LIST_READING_LIBRARY: "v1/admin/libraries/reading-history/list-library",
    LIST_READING_GENRE: "v1/admin/libraries/reading-history/list-genre",
    LIST_CATALOGUE: "v1/admin/libraries/catalogue/list",

    BOOK_RESERVE: "v1/admin/libraries/item-reservation/reserve",
    CHECK_RESERVATION: "v1/admin/libraries/item-reservation/check",
    CANCEL_RESERVE: "v1/admin/libraries/item-reservation/cancel",
    LIST_USER_RESERVED:
      "v1/admin/libraries/item-reservation/list-user-reserved",

    FINE_CALC: "v1/admin/libraries/transaction/fine-calc",
    LIST_FINE: "v1/admin/libraries/transaction/list-fine",
    COUNT_FINE: "v1/admin/libraries/transaction/count-fine",
    PAY_FINE: "v1/admin/libraries/transaction/pay-fine",

    LIST_USER: "v1/admin/libraries/user/list",
    LIST_FINE_SUMMERY: "v1/admin/libraries/fine-management/list-fine-summery",
  },
};
