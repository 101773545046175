import React, { Suspense } from "react";
import { Switch, Route, BrowserRouter, HashRouter } from "react-router-dom";

import { Spinner } from "react-bootstrap";
import ForgotPassword from "../pages/forgotPassword";

const AppPage = React.lazy(() => import("../pages/app"));
const Login = React.lazy(() => import("../pages/login"));
const LoginV2Username = React.lazy(() =>
  import("../pages/loginV2/loginV2Username")
);
const LoginV2Password = React.lazy(() =>
  import("../pages/loginV2/loginV2Password")
);
const LoginType = React.lazy(() => import("../pages/login/loginType"));
const Layout = React.lazy(() => import("../pages/app/layout"));
const MobileLayout = React.lazy(() => import("../pages/mob/layout"));
const MobileLoginView = React.lazy(() =>
  import("../pages/login/mobileLoginView")
);
const Application = React.lazy(() => import("../pages/application/index"));
const Opac = React.lazy(() => import("../pages/opac/index"));
const Helpcenter = React.lazy(() => import("../pages/helpCenter/index"));
const SearchResults = React.lazy(() =>
  import("../pages/helpCenter/searchResults")
);
const ViewTopics = React.lazy(() => import("../pages/helpCenter/viewTopics"));
const EditTopic = React.lazy(() => import("../pages/helpCenter/editTopic"));
export default () => (
  <HashRouter>
    <Suspense
      fallback={
        <div className="text-center" style={{ marginTop: "calc(30vh)" }}>
          <Spinner animation="border" />
        </div>
      }
    >
      <Switch>
        <Route path="/application" component={Application} />
        <Route path="/helpcenter" component={Helpcenter} />
        <Route path="/opac" component={Opac} />
        <Route path="/searchresults" component={SearchResults} />
        <Route path="/viewtopics/:contentId/:parentId" component={ViewTopics} />
        <Route path="/editTopic" component={EditTopic} />
        <Route path="/mob/app" component={MobileLayout} />
        <Route path="/mob" component={MobileLoginView} />

        <Route path="/app" component={Layout} />

        <Route path="/login" component={LoginV2Password} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/" component={LoginType} />
      </Switch>
    </Suspense>
  </HashRouter>
);
